<template>
  <div>
    <div class="container">
      <div class="head_text">
        <span class="tt">支付订单</span>
      </div>
      <div class="Order-List">
        <div class="item-jqcode">
          <div class="item-dec">
            <span>微信支付</span>
            <p>
              距离二维码过期还剩<span class="timer">{{ count }}</span
              >秒,过期后请刷新页面重新获取二维码
            </p>
          </div>
          <div class="item-qr">
            <div id="qrcode" ref="qrcode" class="item-code"></div>
            <div v-if="count <= 0" class="refresh" @click="refreshCode">刷新</div>
          </div>
          <div class="item-ts">
            <img :src="imgicon" alt="" class="item-ic" />
            <div class="item-wx">
              <span>请使用微信扫一扫</span>
              <p>扫描二维码支付</p>
            </div>
          </div>
        </div>
        <img :src="imgPic" alt="" class="item-pic" />
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2' // 引入qrcode
import to from 'await-to'
import { getWXCodePay, getEnterpriseRecharge } from '@/api/college'
var qrcode
export default {
  data() {
    return {
      imgicon: require('../../../assets/college/Accounts/sao.png'),
      imgPic: require('../../../assets/college/Accounts/phone.png'),
      orderType: this.$route.query.orderType,
      paytype: this.$route.query.paytype,
      orderNo: this.$route.query.orderNo,
      obj: JSON.parse(sessionStorage.getItem('codeObj')),
      count: '',
      interval: null,
      codeObj: [{ qrcode: sessionStorage.getItem('urlCode') }],
    }
  },
  created() {
    this.getCode()
    this.crateQrcode()
    this.getWXCodePayStatus()
  },
  mounted() {
    //实现轮询
    this.interval = setInterval(() => {
      this.getWXCodePayStatus()
    }, 3000)
  },
  beforeDestroy() {
    //清除轮询
    clearInterval(this.interval)
    this.interval = null
  },
  methods: {
    async createdCode() {
      const [res, err] = await to(getEnterpriseRecharge(this.obj))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.qrcode = res.data.content
      this.$set(this.codeObj, 0, { qrcode: res.data.content })
    },
    async refreshCode() {
      await this.createdCode()
      this.getCode()
      qrcode.clear()
      qrcode.makeCode(this.codeObj[0].qrcode)
    },
    async getWXCodePayStatus() {
      const [res, err] = await to(getWXCodePay({ orderNo: this.orderNo }))
      if (err) {
        clearInterval(this.interval)
        this.interval = null
        this.$router.push({ path: 'payStatus' })
        return this.$message({ type: 'error', message: '充值失败！' })
      }
      if (res && res.data.result == 'SUCCESS') {
        this.$router.push({
          path: 'payStatus',
          query: { orderId: res.data.orderId, payStatus: res.data.result },
        })
        this.$message({ message: '充值成功！', type: 'success' })
      }
    },
    crateQrcode() {
      this.$nextTick(() => {
        qrcode = new QRCode('qrcode', {
          width: 280,
          height: 280, // 高度
          text: this.codeObj[0].qrcode, // 二维码内容
          background: '#fff',
        })
      })
    },
    getCode() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
  },
}
</script>

<style scoped>
.head_text {
  font-size: 24px;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: left;
  margin: 26px 10px 26px 10px;
}
.tt {
  color: #333333;
}
.Order-List {
  width: 100%;
  height: 675px;
  border: 1px solid #c2c2c2;
  margin-bottom: 50px;
  display: flex;
}
.item-jqcode {
  margin-left: 46px;
  margin-top: 63px;
}
.item-dec {
  display: flex;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  color: #333333;
}
.item-dec span {
  font-size: 24px;
  font-weight: 700px;
}
.item-dec p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  margin-left: 20px;
}
.timer {
  font-size: 14px;
  font-weight: 400;
  color: #ff7674;
}
.item-ts {
  width: 293px;
  height: 78px;
  background: #ff7674;
  margin-left: 150px;
  display: flex;
}
.item-ic {
  width: 40px;
  height: 37px;
  margin: 20px 13px 20px 33px;
}
.item-wx {
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
}
.item-wx span {
  display: inline-block;
  margin-top: 18px;
}
.item-wx p {
  margin-top: 10px;
}
.item-pic {
  width: 320px;
  height: 446px;
  margin: 63px 0 0 43px;
}
.item-qr {
  width: 290px;
  height: 290px;
  margin: 20px 0 20px 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  position: relative;
}
.item-code {
  width: 280px;
  height: 280px;
  padding: 5px 5px;
}
.refresh {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 52px;
  cursor: pointer;
  background-color: #cebdbd;
  font-size: 18px;
}
</style>
